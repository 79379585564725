/* duplicated in packages/@ui/react-quote-stickybid/src/presentation/PresentationPreview/PresentationImbedPreview.tsx */

@font-face {
  font-family: 'degular';
  font-display: auto;
  font-style: normal;
  font-weight: 300;
  src: url('./public/fonts/degular-300.woff2') format('woff2'), url('./public/fonts/degular-300.woff') format('woff');
}

@font-face {
  font-family: 'degular';
  font-display: auto;
  font-style: italic;
  font-weight: 300;
  src: url('./public/fonts/degular-300-italic.woff2') format('woff2'),
    url('./public/fonts/degular-300-italic.woff') format('woff');
}

@font-face {
  font-family: 'degular';
  font-display: auto;
  font-style: normal;
  font-weight: 400;
  src: url('./public/fonts/degular-400.woff2') format('woff2'), url('./public/fonts/degular-400.woff') format('woff');
}

@font-face {
  font-family: 'degular';
  font-display: auto;
  font-style: italic;
  font-weight: 400;
  src: url('./public/fonts/degular-400-italic.woff2') format('woff2'),
    url('./public/fonts/degular-400-italic.woff') format('woff');
}

@font-face {
  font-family: 'degular';
  font-display: auto;
  font-style: normal;
  font-weight: 500;
  src: url('./public/fonts/degular-500.woff2') format('woff2'), url('./public/fonts/degular-500.woff') format('woff');
}

@font-face {
  font-family: 'degular';
  font-display: auto;
  font-style: italic;
  font-weight: 500;
  src: url('./public/fonts/degular-500-italic.woff2') format('woff2'),
    url('./public/fonts/degular-500-italic.woff') format('woff');
}

@font-face {
  font-family: 'degular';
  font-display: auto;
  font-style: normal;
  font-weight: 600;
  src: url('./public/fonts/degular-600.woff2') format('woff2'), url('./public/fonts/degular-600.woff') format('woff');
}

@font-face {
  font-family: 'degular';
  font-display: auto;
  font-style: italic;
  font-weight: 600;
  src: url('./public/fonts/degular-600-italic.woff2') format('woff2'),
    url('./public/fonts/degular-600-italic.woff') format('woff');
}

html {
  font-size: 16px;

  /* font-family: 'Roboto', Arial, Helvetica, sans-serif; */
  font-family: 'degular', sans-serif;
}

body {
  line-height: 1.42857143;
}

/* :focus-visible polyfill to remove hide focus when interacted with mouse */
button:focus:not(.focus-visible) {
  outline: none;
}

@media print {
  body {
    min-width: 1024px;
    border-bottom: 1px solid transparent;
  }
}

strong {
  font-weight: 500;
}

fieldset {
  border: 0;
  padding: 0.01em 0 0 0;
  margin: 0;
  min-width: 0;
}
